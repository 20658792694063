import { InMemoryCache } from 'apollo-cache-inmemory'
import { createUploadLink } from 'apollo-upload-client'
import React from "react";
import App from './App';
import {ApolloClient} from "apollo-client"
import {ApolloProvider} from "react-apollo"
import {graphQL} from "./auth_config.json"
const apolloCache = new InMemoryCache()
const uploadLink = createUploadLink({
	uri: graphQL,
	headers: {
		"keep-alive": "true"
	}
})
class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false, error: '' };
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true, error };
	}

	componentDidCatch(error, errorInfo) {
		// You can also log the error to an error reporting service
		console.log(error, errorInfo);
	}

	render() {
		console.log(this.state)
		if (this.state.hasError) {

			// You can render any custom fallback UI
			return <>
				<h1>Service is temporarily unavailable. We apologize for any inconvenience caused. You can book via normal counter service. </h1>
				<p>Error:  {this.state.error}</p>
			</>
		}

		return this.props.children;
	}
}

const ApolloClientCreator = () => {

	const client = new ApolloClient({
		cache: apolloCache,
		link: uploadLink,
		onError: ({ graphQLErrors, networkError}) => {
			if (networkError) {
				console.log("A network error has occurred.")
			}else if(graphQLErrors){
				console.log("A graphql error has occurred.")
			}else{
				console.log("Some error occurred.")
			}
		},
	});
	return (
		<ErrorBoundary>
		<ApolloProvider client = {client}>
			<App />
		</ApolloProvider>
		</ErrorBoundary>
	);
};

export default ApolloClientCreator;