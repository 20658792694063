import {useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';

const GET_ALL_RESTAURANTS_FROM_APP = gql`

    query getAllRestaurantsFromApp{
        getAllRestaurantsFromApp{
            actual_id
            name
            logo
            path
            image
            locality
            categories{
                actual_id
                items{
                    actual_id
                    name
                    image
                    price
                    short_description
                    ingredients{
                        actual_id
                        name
                    }
                    percentageOff
                }
                image
                category_name
                percentageOff
            }
        }
    }`;

function GetAllRestaurantsFromApp() {
    //executing the query
    const {loading, data, error} = useQuery(GET_ALL_RESTAURANTS_FROM_APP)

    if (loading) {
        return "LOADING";
    }
    if (error != null) {
        if (error.networkError)
        {
            throw(error.networkError);
        }
        else
        {
            error.graphQLErrors.forEach(err => {
                throw(err.message);
            });
        }
    }else{
        console.log(data)
        return data.getAllRestaurantsFromApp;
    }
}

export default GetAllRestaurantsFromApp;
