import React, {useState, useCallback, useEffect} from "react";
import './App.css';
import neural_solutions_logo from './neural_solutions.png';
import sit_and_order_logo from './icon.png'
import qr_icon from './qr-icon.png';
import { Routes, Route, Link, useNavigate, useLocation} from "react-router-dom";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { QrReader } from 'react-qr-reader';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Slide from '@mui/material/Slide';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { QuantityPicker } from 'react-qty-picker';
import TextField from '@mui/material/TextField';
import {makeStyles} from '@material-ui/core/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import GetAllRestaurantsFromApp from "./graphql/getAllRestaurantsFromApp";

import HelpIcon from '@material-ui/icons/Help';
import InfoIcon from '@material-ui/icons/Info';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import GetAdverts from "./graphql/getAdverts";
import {ADD_ORDER} from "./graphql/addOrder";
import {Button} from "@material-ui/core";
import {Mutation} from "react-apollo"
import {useLazyQuery, useMutation} from "@apollo/react-hooks";
import {CONTACT_US} from "./graphql/contactUs";
import gql from "graphql-tag";

function App() {
    const restaurants = GetAllRestaurantsFromApp()
    if(restaurants === "LOADING"){
        return(
            <div style={{
                height: "100vh",
                width: "100vw",
                backgroundColor: "#f5683f",
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <img style={{width: "100%", maxWidth: "250px", marginBottom: "10px"}} src={sit_and_order_logo}/>
                <Loader
                    type="ThreeDots"
                    color="white"
                    timeout={100000}
                />
            </div>
        )
    }else {
        return (
        <div className="App">
            <Routes>
                <Route path="/" element={<Home restaurants={restaurants}/>} />
                <Route path="/info" element={<Info />} />
                <Route path="/contact" element={<Contact />} />
                {restaurants.map(restaurant => (
                    <Route path={restaurant.path} element={<Restaurant data={restaurant}/>} />
                ))}
                <Route path="/qr-scanner" element={<QrReaderPage restaurants={restaurants}/>} />
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </div>
        );
    }
}

function MainHeader(){
    return (
        <div className="header">
            <div className="header_flex">
                <Link to="/">
                    <h1>Sit & Order</h1>
                </Link>
                <div className="icons_right">
                    <Link to="/info" className="icon_link_right">
                        <InfoIcon fontSize="large"/>
                    </Link>
                    <Link to="/contact" className="icon_link">
                        <HelpIcon fontSize="large"/>
                    </Link>
                </div>
            </div>
        </div>
    )
}

function MainFooter(){
    return (
        <>
            <Link to="/qr-scanner">
                <div className="qr_fixed">
                    <img className="qr_image" src={qr_icon}/>
                </div>
            </Link>
            <footer>
                <h6 className="copyright_h6">© 2022 SIT & ORDER </h6>
                <div className="footer_menu">
                    <h6 style={{paddingRight: "5px", margin: "0px"}}>DEVELOPED BY:</h6>
                    <a className="logo_align" href="https://www.neuralsolutions.eu/" target="_blank">
                        <img style={{maxHeight: "40px"}} src={neural_solutions_logo}/>
                    </a>
                </div>
            </footer>
        </>
    )
}


function Home(props){
    return(
        <>
            <MainHeader/>
            <div className="body">
                <div className="body_flex">
                    {props.restaurants.map(restaurant => (
                        <Link to={restaurant.path}>
                            <div className="restaurant_box">
                                <img className="restaurant_image" src={restaurant.image} />
                                <div className="restaurant_box_bottom">
                                    <h3>{restaurant.name}</h3>
                                    <ArrowCircleRightOutlinedIcon className="icon_arrow" fontSize="large"/>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
            <MainFooter/>
        </>
    )
}

function Info(){
    return (
        <>
            <MainHeader/>
            <div className="body">
                <h3 style={{marginTop: "30px", marginBottom: "10px"}}>Instructions</h3>
                <h4>1. Click on the QR code icon to open the QR Scanner</h4>
                <h4>2. Scan the QR code printed on the table</h4>
                <h4>3. Select your order</h4>
                <h4>4. Click on the Shopping Cart icon</h4>
                <h4>5. Confirm your order and click checkout</h4>
                <h4>6. Enjoy your food!</h4>
            </div>
            <MainFooter/>
        </>
    )
}

function Contact(){

    const [submitted, setSubmitted] = useState(false);
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [name, setName] = useState("");
    const handleSubmit = () => {
        setSubmitted(true);
    };
    const [contact] = useMutation(CONTACT_US);

    return (
        <>
            <MainHeader/>
            <div className="body">
                <h3 style={{marginTop: "30px", marginBottom: "10px"}}>Contact Details</h3>
                <div className="contact_info">
                    <div className="contact_info_section">
                        <PhoneIcon className="contact_icon"/>
                        <h4>+356 7983 0593</h4>
                    </div>
                    <div className="contact_info_section">
                        <EmailIcon className="contact_icon"/>
                        <h4>info@sitandorder.mt</h4>
                    </div>
                </div>
                <h3 style={{marginTop: "30px", marginBottom: "20px"}}>Contact Sit & Order</h3>
                {submitted ?
                    <h2 className="form_submitted">Form submitted successfully. We will be getting back in touch with you.</h2>
                    :
                    <form
                        onSubmit={e=>{

                            e.preventDefault()
                            contact({
                                variables: {
                                    email,
                                    name,
                                    message,
                                }
                            }).then((result) => {
                                console.log(result)
                                handleSubmit()
                            })
                        }}
                    >
                        <div className="form_field_div">
                            <input
                                type="text"
                                placeholder="Your name"
                                name="name"
                                required
                                className="form_field"
                                value={name}
                                onChange={e => {
                                    setName(e.target.value)
                                }}

                            />
                        </div>
                        <div className="form_field_div">
                            <input
                                type="email"
                                placeholder="Email"
                                name="email"
                                required
                                className="form_field"
                                value={email}
                                onChange={e => {
                                    setEmail(e.target.value)
                                }}
                            />
                        </div>
                        <div className="form_field_div">
                        <textarea
                            placeholder="Your message"
                            name="message"
                            required
                            className="form_field"
                            rows="3"
                            value={message}
                            onChange={e => {
                                setMessage(e.target.value)
                            }}
                        />
                        </div>
                        <div className="form_field_div">
                            <button
                                type="submit"
                                className="form_button"
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                }
            </div>
            <MainFooter/>
        </>
    )
}

function PageNotFound(){
    return (
        <>
            <MainHeader/>
            <div className="body">
                <h3>404: Page Not Found</h3>
            </div>
            <MainFooter/>
        </>
    )
}

function QrReaderPage(props){

    const [qrCodeResult, setQrCodeResult] = useState(null);
    // const [qrCodeResult, setQrCodeResult] = useState("https://sitandorder.mt/black-pearl/?tid=lVyVTRQP8CXYnMxZGNXi");
    const [status_text, setStatusText] = useState('SCAN THE QR CODE ON YOUR TABLE')
    const [restaurant_path, setRestaurantPath] = useState('')
    const [table_id, setTableID] = useState('')

    const navigate = useNavigate();
    const CHECK_IF_TABLE_ID_EXISTS = gql`

    query validateTableID($id: ID!){
        validateTableID(id:$id)
    }`;

    const CHECK_UNIQUE_USER_ID = gql`

    query checkUniqueUserID($uniqueUserID: ID!){
        checkUniqueUserID(uniqueUserID:$uniqueUserID){
            path
            tableID
        }
    }`;

    const [checkIfTableIDExists,  { loading, error, data }] = useLazyQuery(CHECK_IF_TABLE_ID_EXISTS,
        {
            fetchPolicy: 'network-only',
        })

    const [checkUniqueUserID,  res] = useLazyQuery(CHECK_UNIQUE_USER_ID,
        {
            fetchPolicy: 'network-only',
        })

    useEffect(()=>{
        if (data?.validateTableID != null) {
            localStorage.setItem("uniqueUserID", data.validateTableID)
            navigate("/" + restaurant_path, {state: {table_id: table_id}});

        } else if(data == null){
            setStatusText('SCAN THE QR CODE ON YOUR TABLE.')
        }

        if(error != null) {
            setStatusText(error.graphQLErrors.map(x => x.message)[0])
        }
    }, [data, error])

    useEffect(()=>{
        console.log(res?.data?.checkUniqueUserID)
        if (res?.data?.checkUniqueUserID.tableID != null) {
            navigate("/" + res.data.checkUniqueUserID.path, {state: {table_id: res?.data?.checkUniqueUserID.tableID}});

        } else if(res?.data == null){
            setStatusText('SCAN THE QR CODE ON YOUR TABLE.')
        }

        if(error != null) {
            setStatusText(error.graphQLErrors.map(x => x.message)[0])
        }
    }, [res.data, res.error])

    useEffect(()=>{
        if(localStorage.getItem("uniqueUserID") != null){
            checkUniqueUserID({ variables: { uniqueUserID: localStorage.getItem("uniqueUserID") } })
        }
    }, [])

    useEffect(() => {
        // Front-end validation
        if(qrCodeResult)
        {
            console.log({qrCodeResult})
            if (qrCodeResult.startsWith("https://sitandorder.mt/"))
            {
                const without_domain = qrCodeResult.slice(23)
                const split = without_domain.split('/');
                if (split.length === 2)
                {
                    // check that restaurant path is valid
                    if(props.restaurants.some(restaurant => restaurant.path === split[0]))
                    {
                        if (split[1].startsWith("?tid="))
                        {
                            const without_tid = split[1].slice(5)
                            setRestaurantPath(split[0])
                            setTableID(without_tid)
                            checkIfTableIDExists({ variables: { id: without_tid } })
                        }
                        else
                        {
                            setStatusText('QR CODE SCANNED IS NOT A VALID RESTAURANT TABLE QR CODE. PLEASE TRY AGAIN.')
                        }
                    }
                    else
                    {
                        setStatusText('QR CODE SCANNED IS NOT A VALID RESTAURANT TABLE QR CODE. PLEASE TRY AGAIN.')
                    }
                }
                else
                {
                    setStatusText('QR CODE SCANNED IS NOT A VALID RESTAURANT TABLE QR CODE. PLEASE TRY AGAIN.')
                }
            }
            else
            {
                setStatusText('QR CODE SCANNED IS NOT A VALID RESTAURANT TABLE QR CODE. PLEASE TRY AGAIN.')
            }
        }
    }, [qrCodeResult]);


    // link to the page of the restaurant - send as props the table id (mark as occupied, check that table is NOT occupied before redirecting etc)
    console.log(qrCodeResult)
    return (
        <div className="qr_body">
            <Button style={{position: "absolute", top:"10px", right: "10px"}} onClick={() => navigate(-1)}>
                <CloseIcon style={{color: "white"}} fontSize="large"/>
            </Button>
            <div className="qr_main">
                <div className="qr_reader_screen">
                    <QrReader
                        constraints={{facingMode: 'environment'}}
                        scanDelay={500}
                        onResult={(result, error) => {

                            if (result) {
                                console.log({qrCodeResult, result})
                                setQrCodeResult(result.text);
                            }
                        }}
                        style={{ width: '100%'}}
                    />
                </div>
                <h3 className="qr_text">{status_text}</h3>
            </div>
        </div>
    )
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function MenuItemModal(props){

    const [quantity, setQuantity] = React.useState(1);
    const [ingredientsToRemove, setIngredientsToRemove] = React.useState([]);

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const [openSuccessfulAlert, setOpenSuccessfulAlert] = React.useState(false);

    const handleCloseSuccessfulAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccessfulAlert(false);
    };

    const handleCheckboxChange = (event) => {

        const id = event.target.name.split("_")[0]
        const name = event.target.name.split("_")[1]

        const itemsFound = ingredientsToRemove.filter(item => {return item.actual_id === id})

        if (itemsFound.length > 0) //if already in list, we need to remove
        {
            const newList = ingredientsToRemove.filter(item => {return item.actual_id !== id});

            console.log(newList)

            setIngredientsToRemove(newList);
        }
        else //if not in list, we need to add
        {
            setIngredientsToRemove([...ingredientsToRemove, {'actual_id': id, 'name': name}])
        }
    };

    const getPickerValue = (value) =>{
        setQuantity(value)
    }

    function addItem() {
        props.setOrder([...props.order,
            {'actual_id': props.currentItem.actual_id,
                'name': props.currentItem.name,
                'description': props.currentItem.short_description,
                'price': props.currentItem.price,
                'quantity': quantity,
                'ingredients': ingredientsToRemove
            }])

        props.setOrderTotal(Number(props.orderTotal) + Number((props.currentItem.price * quantity).toFixed(2)))
        props.setQuantityTotal(Number(props.quantityTotal) + quantity)

        setOpenSuccessfulAlert(true);

        props.handleCloseItem();

        setQuantity(1);
        setIngredientsToRemove([]);
    }

    function onClickClose(){
        props.handleCloseItem();

        setQuantity(1);
        setIngredientsToRemove([]);
    }

    return(
        <>
            <Snackbar open={openSuccessfulAlert} autoHideDuration={4000} onClose={handleCloseSuccessfulAlert}>
                <Alert onClose={handleCloseSuccessfulAlert} severity="success" sx={{ width: '100%', backgroudColor: "red" }}>
                    Item added to basket
                </Alert>
            </Snackbar>
            <Dialog
                fullScreen
                open={props.openItem}
                onClose={onClickClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar style={{backgroundColor: "black"}}>
                        <div style={{flex: 1}}>
                            <h1 className="restaurant_name">{props.currentItem.name}</h1>
                        </div>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={onClickClose}
                            aria-label="close"
                            style={{padding: "0px"}}
                        >
                            <CloseIcon fontSize="large"/>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <img src={props.currentItem.image}/>
                <div className="item_body">
                    <h3 className="h4_restaurant">{props.currentItem.short_description}</h3>
                    <h3 className="h3_restaurant">€{props.currentItem.price.toFixed(2)}</h3>
                    <div className="divider_food_item"></div>
                    <h3 className="h3_restaurant">Ingredients to Remove</h3>
                    <FormGroup className="food_items_group">
                        {props.currentItem.ingredients.map(ingredient => (
                            <FormControlLabel control={<Checkbox sx={{
                                "&.Mui-checked": {
                                    "&, & + .MuiFormControlLabel-label": {
                                        color: "black"
                                    }
                                }
                            }} />} name={ingredient.actual_id+"_"+ingredient.name} label={ingredient.name} onChange={handleCheckboxChange} className="food_items"/>
                        ))}
                    </FormGroup>
                    <div className="divider_food_item"></div>
                    <h3 className="h3_restaurant">Quantity</h3>
                    <div className="quantity_picker">
                        <QuantityPicker smooth min={1} max={30} value={1} onChange={getPickerValue}/>
                    </div>
                    <div className="add_to_order">
                        <Button style={{width: "100%"}} onClick={addItem} variant="contained" endIcon={<ShoppingCartIcon fontSize="large"/>}>€{(props.currentItem.price*quantity).toFixed(2)} - Add to Order</Button>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

function CheckoutModal(props){

    const useStyles = makeStyles({
        root: {
            "& .MuiOutlinedInput-input": {
                color: "grey",
            },
            "& .MuiInputLabel-root": {
                color: "grey",
            },
            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: "grey",
            },
            "&:hover .MuiOutlinedInput-input": {
                color: "grey",
            },
            "&:hover .MuiInputLabel-root": {
                color: "grey",
            },
            "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: "grey",
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
                color: "#101616",
            },
            "& .MuiInputLabel-root.Mui-focused": {
                color: "#101616",
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#101616",
            }
        }
    });

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const [openSuccessfulAlert, setOpenSuccessfulAlert] = React.useState(false);

    const handleCloseSuccessfulAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccessfulAlert(false);
    };

    const [openRemoveAlert, setOpenRemoveAlert] = React.useState(false);

    const handleCloseRemoveAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenRemoveAlert(false);
    };

    const classes = useStyles();

    const [note, setNote] = React.useState('');

    const quantityChange = (newQuantity, index) => {

        const itemSelected = props.order[index]

        console.log(itemSelected)

        const newList = props.order.map((item, indexIn) => {
            if (indexIn === index) {
                return {
                    ...item,
                    quantity: Number(newQuantity),
                };
            }
            return item;
        });

        console.log(newList)

        props.setOrder(newList);

        const newTotal = (Number(Number(props.orderTotal) + (Number(itemSelected.price)) * (Number(newQuantity) - Number(itemSelected.quantity)))).toFixed(2)
        props.setOrderTotal(newTotal)

        props.setQuantityTotal(Number(props.quantityTotal) - Number(itemSelected.quantity) + Number(newQuantity))

    };

    const removeItem = (index) => {

        const itemToRemove = props.order[index]

        const newList = props.order.filter((item) => item !== itemToRemove);

        props.setOrderTotal((props.orderTotal - (itemToRemove.price*itemToRemove.quantity)).toFixed(2))
        props.setQuantityTotal(Number(props.quantityTotal) - Number(itemToRemove.quantity))

        props.setOrder(newList)

        setOpenRemoveAlert(true)

        if(newList.length < 1)
        {
            props.handleCloseCheckout()
        }

    }

    const handleNoteChange = (event) => {
        setNote(event.target.value);
    };

    return(
        <>
            <Snackbar open={openSuccessfulAlert} autoHideDuration={4000} onClose={handleCloseSuccessfulAlert}>
                <Alert onClose={handleCloseSuccessfulAlert} severity="success" sx={{ width: '100%'}}>
                    Order Successful!
                </Alert>
            </Snackbar>
            <Snackbar open={openRemoveAlert} autoHideDuration={4000} onClose={handleCloseRemoveAlert}>
                <Alert onClose={handleCloseRemoveAlert} severity="error" sx={{ width: '100%'}}>
                    Item removed from basket
                </Alert>
            </Snackbar>
            <Dialog
                fullScreen
                open={props.openCheckout}
                onClose={props.handleCloseCheckout}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar style={{backgroundColor: "black"}}>
                        <div style={{flex: 1}}>
                            <h1 className="restaurant_name">Checkout</h1>
                        </div>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={props.handleCloseCheckout}
                            aria-label="close"
                        >
                            <CloseIcon fontSize="large"/>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <div className="item_body">
                    {props.ordersConfirmed.length > 0 &&
                    <div style={{marginBottom: "30px"}}>
                        {props.ordersConfirmed.map((order_confirmed_item, index) => (
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <h3 className="h3_restaurant">Previous Order #{index+1}</h3>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <h3 className={"h3_restaurant"}>Order Summary</h3>
                                    <div style={{marginBottom: "20px"}}>
                                        <h3 className="h4_restaurant_less_padding">Order Total: €{Number(order_confirmed_item.order_total).toFixed(2)}</h3>
                                        {order_confirmed_item.note !== "" &&
                                        <h3 className="h4_restaurant_less_padding">Additional Notes: {order_confirmed_item.note}</h3>
                                        }
                                    </div>
                                    {order_confirmed_item.order_items.map((order_item) => (
                                        <>
                                            <div className="menu_item_checkout">
                                                <div className="menu_item_description">
                                                    <div style={{display: "flex", alignItems: "center"}}>
                                                        <h3 className="h3_restaurant" style={{marginRight: "10px"}}>{order_item.name}</h3>
                                                    </div>
                                                    <h3 className="h4_restaurant">{order_item.description}</h3>
                                                    {order_item.ingredients.length > 0 &&
                                                    <>
                                                        <h3 className="h4_restaurant">Ingredients Removed:</h3>
                                                        {order_item.ingredients.map(ingredient => (
                                                            <h3 className="h5_restaurant">{ingredient.name}</h3>
                                                        ))}
                                                    </>
                                                    }
                                                    <h3 className="h4_restaurant">Quantity: {order_item.quantity}</h3>
                                                    <h3 className="h3_restaurant" style={{marginTop: "20px"}}>€{(order_item.price * order_item.quantity).toFixed(2)}</h3>
                                                </div>
                                            </div>
                                        </>
                                    ))}
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </div>
                    }
                    {props.order.length > 0 &&
                    <div>
                        <div className="body_flex">
                            {props.order.map((order_item, index) => (
                                <>
                                    <div className="menu_item_checkout">
                                        <div className="menu_item_description">
                                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                                <h3 className="h3_restaurant" style={{marginRight: "10px"}}>{order_item.name}</h3>
                                                <IconButton
                                                    edge="start"
                                                    color="inherit"
                                                    onClick={() => removeItem(index)}
                                                    aria-label="close"
                                                >
                                                    <CloseIcon fontSize="medium"/>
                                                </IconButton>
                                            </div>
                                            <h3 className="h4_restaurant">{order_item.description}</h3>

                                            {order_item.ingredients.length > 0 &&
                                            <>
                                                <h3 className="h4_restaurant">Ingredients Removed:</h3>
                                                {order_item.ingredients.map(ingredient => (
                                                    <h3 className="h5_restaurant">{ingredient.name}</h3>
                                                ))}
                                            </>
                                            }

                                            <h3 className="h4_restaurant">Quantity:</h3>
                                            <div className="quantity_picker_checkout">
                                                <QuantityPicker smooth min={1} max={30} value={order_item.quantity} onChange={(value) => quantityChange(value, index)}/>
                                            </div>
                                            <h3 className="h3_restaurant" style={{marginTop: "20px"}}>€{(order_item.price * order_item.quantity).toFixed(2)}</h3>
                                        </div>
                                    </div>
                                </>
                            ))}
                        </div>
                        <div className="add_to_order_checkout">
                            <TextField
                                label="Additional Notes"
                                style={{width: "100%", paddingBottom: "20px"}}
                                className={classes.root}
                                sx={{
                                    input: {
                                        color: "red",
                                        background: "green"
                                    }
                                }}
                                multiline
                                rows={3}
                                defaultValue="Allergies, additional requests, etc.."
                                value={note}
                                onChange={handleNoteChange}
                            />
                            <Mutation mutation={ADD_ORDER}>
                                {(addOrder, {loading}) => {
                                    return (
                                        <Button
                                            style={{width: "100%"}}
                                            variant="contained"
                                            endIcon={<CheckCircleOutlineIcon fontSize="large"/>}
                                            disabled=
                                                {
                                                    (loading)
                                                }
                                            onClick=
                                                {() => {
                                                    const menuItemIDs = []

                                                    for(let i = 0; i < props.order.length; i++)
                                                    {
                                                        const ingredientsToRemove = []

                                                        for(let j = 0; j < props.order[i].ingredients.length; j++)
                                                        {
                                                            ingredientsToRemove.push(Number(props.order[i].ingredients[j].actual_id))
                                                        }
                                                        console.log(props.order[i])

                                                        menuItemIDs.push({
                                                            ID: props.order[i].actual_id,
                                                            quantity: props.order[i].quantity,
                                                            note: '',
                                                            ingredientsToRemove: ingredientsToRemove

                                                        })
                                                    }

                                                    addOrder({
                                                        variables: {
                                                            tableID: props.tableId,
                                                            menuItemIDs: menuItemIDs,
                                                            note: note,
                                                            uniqueUserID: localStorage.getItem("uniqueUserID")
                                                        }
                                                    }).then((result) => {
                                                        if (result.data.addOrder)
                                                        {
                                                            props.setOrdersConfirmed([...props.ordersConfirmed, {'order_items': props.order, 'note': note, 'order_total': props.orderTotal}])
                                                            setNote('');
                                                            props.setOrderTotal(0);
                                                            props.setQuantityTotal(0);
                                                            props.setOrder([])
                                                            props.handleCloseCheckout();
                                                            setOpenSuccessfulAlert(true);
                                                        }
                                                    }).catch(err=>{
                                                        alert(err.message + "\nService is temporarily unavailable. We apologize for any inconvenience caused. You can book via normal counter service.")

                                                    })
                                                }

                                                }>
                                            €{Number(props.orderTotal).toFixed(2)} - Confirm Order
                                        </Button>
                                    )
                                }}
                            </Mutation>
                        </div>
                    </div>
                    }
                </div>
            </Dialog>
        </>
    )
}


function Restaurant(props){

    const [current_category, setCurrentCategory] = React.useState(props.data.categories[0]);
    const [current_category_order, setCurrentCategoryOrder] = React.useState(0);

    const {state} = useLocation();
    const table_id = state ? state.table_id : null

    const [orderTotal, setOrderTotal] = React.useState(0)
    const [quantityTotal, setQuantityTotal] = React.useState(0)
    const [order, setOrder] = React.useState([])

    const [ordersConfirmed, setOrdersConfirmed] = React.useState([])

    const [currentItem, setCurrentItem] = React.useState(props.data.categories[0].items[0]);
    const [openItem, setOpenItem] = React.useState(false);

    const handleOpenItem = (item) => {
        if(table_id)
        {
            setCurrentItem(item);
            setOpenItem(true);
        }
    };
    const handleCloseItem = () => {
        setOpenItem(false);
    };

    const [openCheckout, setOpenCheckout] = React.useState(false);

    const handleOpenCheckout = (item) => {
        if(order.length > 0 || ordersConfirmed.length > 0)
        {
            setOpenCheckout(true);
        }
    };
    const handleCloseCheckout = () => {
        setOpenCheckout(false);
    };

    const phoneNumber = "tel:" + props.data.phone_number

    const data3 = GetAdverts()

    return(
        <>
            <MenuItemModal quantityTotal={quantityTotal} setQuantityTotal={setQuantityTotal} orderTotal={orderTotal} setOrderTotal={setOrderTotal} order={order} setOrder={setOrder} currentItem={currentItem} openItem={openItem} setOpenItem={setOpenItem} handleOpenItem={handleOpenItem} handleCloseItem={handleCloseItem}/>
            <CheckoutModal ordersConfirmed={ordersConfirmed} setOrdersConfirmed={setOrdersConfirmed} tableId={table_id} quantityTotal={quantityTotal} setQuantityTotal={setQuantityTotal} orderTotal={orderTotal} setOrderTotal={setOrderTotal} order={order} setOrder={setOrder} openCheckout={openCheckout} setOpenCheckout={setOpenCheckout} handleOpenCheckout={handleOpenCheckout} handleCloseCheckout={handleCloseCheckout}/>

            {data3 &&
                <div className="ad" style={{display: "flex", justifyContent: "center"}}>
                    <a href={data3[0].url} style={{display: "inline-block"}}>
                        <img src={data3[0].pic_path} style={{width: "100%", maxHeight: "70px", maxWidth: "430px", display:"block"}}/>
                    </a>
                </div>
            }
            <div className="header_restaurant">
                <div className="header_flex">
                    <h1 className="restaurant_name">{props.data.name}</h1>
                    <a href={phoneNumber} className="icons_right" >
                        <PhoneIcon fontSize="large"/>
                    </a>
                </div>
            </div>
            <div className="body_restaurant">
                <div className="category_scroller">
                    <BottomNavigation
                        sx={{bgcolor: '#ececec'}}
                        showLabels
                        value={current_category_order}
                        onChange={(event, newCategoryName) => {
                            console.log("SETTING NEW CATEGORY")
                            console.log(props.data.categories[newCategoryName])
                            setCurrentCategoryOrder(newCategoryName);
                            setCurrentCategory(props.data.categories[newCategoryName]);
                        }}
                    >
                        {props.data.categories.map(category => (
                            <BottomNavigationAction label={category.category_name} sx={{"& .Mui-selected, .Mui-selected > svg": {color: "black", paddingBottom: "3px", borderBottom: "2px solid black"}}} icon={<img className="icon_category" src={category.image}/>}/>
                        ))}
                    </BottomNavigation>
                </div>
                <div className="body_flex">
                    {current_category.items.map(item => (
                        <>
                            <div className="menu_item" onClick={() => handleOpenItem(item)}>
                                <div className="menu_item_description">
                                    <h3 className="h3_restaurant">{item.name}</h3>
                                    <h3 className="h4_restaurant">{item.short_description}</h3>
                                    <div style={{display: "flex", alignItems: "center"}}>
                                        <h3 className="h3_restaurant">€{item.price.toFixed(2)}</h3>
                                        <ArrowCircleRightOutlinedIcon className="icon_arrow_restaurant" fontSize="large"/>
                                    </div>
                                </div>
                                <div className="menu_item_picture">
                                    <img src={item.image}/>
                                </div>
                            </div>
                            <div className="divider"></div>
                        </>
                    ))}
                </div>
            </div>
            <div className="restaurant_bottom_bar">
                {table_id ?
                    <>
                        <h3 className="h3_restaurant_footer">TABLE REGISTERED</h3>
                        <div className="restaurant_bottom_icon_cart" onClick={handleOpenCheckout}>
                            <ShoppingCartIcon fontSize="large"/>
                            <div className="cart_items_counter">
                                <h3 className="cart_items_counter_number">{quantityTotal}</h3>
                            </div>
                            {ordersConfirmed.length > 0 &&
                                <div className="previous_orders_counter">
                                    <h3 className="previous_orders_counter_number">{ordersConfirmed.length}</h3>
                                </div>
                            }
                        </div>

                    </>
                :
                    <>
                        <h3 className="h3_restaurant_footer">SCAN QR CODE</h3>
                        <div className="restaurant_bottom_icon">
                            <Link to="/qr-scanner">
                                <img className="qr_image" src={qr_icon}/>
                            </Link>
                        </div>
                    </>
                }
            </div>
        </>
    )
}


export default App;
