import {useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';

const GET_ADVERTS = gql`

    query getAdverts{
        getAdverts{
            pic_path
            url
        }
    }`;

function GetAdverts() {
    //executing the query
    const {loading, data, error} = useQuery(GET_ADVERTS)

    if (loading) {
        return "LOADING";
    }

    if (error != null) {
        if (error.networkError)
        {
            throw(error.networkError);
        }
        else
        {
            error.graphQLErrors.forEach(err => {
                throw(err.message);
            });
        }
    }else {
        return data.getAdverts;
    }
}

export default GetAdverts;
